import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SharedUserService } from '../../shared/user/shared-user.service';
import { map } from 'rxjs';

export const isUserLoggedInGuard: CanActivateFn = (route, state) => {
  let _userService: SharedUserService = inject(SharedUserService);
  let _router: Router = inject(Router);

  return _userService.loggedIn$.pipe(
    map((response) => {
      if (response) {
        return true;
      }
      else {
        _router.navigate(['/auth/login']);
        return false;
      }
    })
  );
};
